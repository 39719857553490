<template>
  <div class="text-warning">
    <feather-icon size="24" icon="AlertCircleIcon" />
    <span style="padding-left: 5px; ">{{$t('staff.impersonate_now')}}</span>
    <b-button variant="info" size="sm" style="margin-left: 10px; font-size: 14px" @click="quit">
      {{$t('staff.impersonate_quit')}}
    </b-button>
  </div>
</template>

<script>
import {BButton} from 'bootstrap-vue'
import common from "@/common";

export default {
  name: "impersonate",
  components: {
    BButton
  },
  methods: {
    quit: function () {
      common.showConfirm(common.getI18n('staff.impersonate_quit_confirm'), '', function () {
        common.apiPostData('/admin/StopImpersonate').then(res => {
          common.logout()
        })
      })
    }
  }
}
</script>

<style scoped>

</style>
