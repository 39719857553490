<template>
  <b-nav-item
    v-if="['OneMonthRemained', 'ThreeMonthRemained'].includes(role.contractStatus)"
    class="item" :class="{
      'info-danger': role.contractStatus === 'OneMonthRemained',
      'info-warning': role.contractStatus === 'ThreeMonthRemained',
    }"
  >
    <feather-icon id="contract-status-flag" class="icon" size="21" icon="AlertCircleIcon"/>
    <b-tooltip target="contract-status-flag">
      <div class="tooltip-content">
        <div>{{ infoTitle }}</div>
        <div>{{ infoText }}</div>
      </div>
    </b-tooltip>
  </b-nav-item>
</template>

<script>
import { BNavItem } from 'bootstrap-vue'
import common from '@/common'

export default {
  components: {
    BNavItem,
  },
  data() {
    return {
      role: common.getRole() ?? {},
    }
  },
  computed: {
    infoTitle() {
      return common.getI18n(`facility.contractStatusTip_${this.role.contractStatus}`)
    },
    infoText() {
      return this.role.facilityName + (
        this.role.contractCountDownDay > 0
          ? ' (' + common.getI18n('facility.contractStatus_days_remain').replace('{_field_}', this.role.contractCountDownDay) + ')'
          : ''
      )
    },
  },
  mounted() {
    // 如果當前院舍合約即將到期（一個月内），右上角的感嘆號自動展示詳細内容
    if (['OneMonthRemained'].includes(this.role.contractStatus)) {
      setTimeout(() => this.$root.$emit('bv::show::tooltip', 'contract-status-flag'), 200)
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables';

.item a {
  cursor: default;
}

.info-danger * {
  color: $red;
}

.info-warning * {
  color: $orange;
}

.info-normal * {
  color: inherit;
}

.tooltip-content {
  padding: 0.5rem;
  font-size: 1rem;
  text-align: left;

  > div {
    margin-bottom: 0.5rem;
  }
  > div:last-child {
    margin-bottom: 0;
  }
}
</style>
